
import notPreset from '@/pages/mixins/notice/mix.notice.preset'

const NotParts = notPreset.Parts
const NotNames = notPreset.Names

const NavHome = {
  text: '首页',
  disabled: false,
  href: '/',
}

const NavSelf = {
  text: '资讯中心',
  disabled: false,
  href: '/notice',
}

const NavCurrent = {
  text: '导航台',
  disabled: true,
  href: '',
}

export default {
  data () {
    return {
      navigated: true,
      navHome: NavHome,
      navSelf: NavSelf,
      navCrumbs: [
        NavHome,
        NavSelf,
        NavCurrent
      ],
      navPage: {
        current: 1,
        length: 15,
        totalVisible: 7
      },
      catNavigate: NotParts[NotNames.NAVIGATE],
      catInform: NotParts[NotNames.INFORMS],
      catNews: NotParts[NotNames.NEWS],
      catPublic: NotParts[NotNames.PUBLICS],
      catPark: NotParts[NotNames.PARKS],
      catProject: NotParts[NotNames.PROJECTS],
      catListKeys: [
        notPreset.Names.INFORMS,
        notPreset.Names.NEWS,
        notPreset.Names.PUBLICS,
        notPreset.Names.PARKS,
        notPreset.Names.PROJECTS,
        notPreset.Names.YUANSHI
      ],
      currentCategory: {},
      navCategory: {
        title: '资讯中心',
        name: 'notice',
        active: 0,
        items: [
          NotParts[NotNames.NAVIGATE],
          NotParts[NotNames.INFORMS],
          NotParts[NotNames.NEWS],
          NotParts[NotNames.PUBLICS],
          NotParts[NotNames.PARKS],
          NotParts[NotNames.PROJECTS],
          NotParts[NotNames.YUANSHI]
        ]
      }
    }
  },
  methods: {
    getCategory (name) {
      let result = null
      const cats = this.navCategory.items
      for (const i in cats) {
        const cat = cats[i]
        if (cat.name === name) {
          result = cat
          break
        }
      }
      return result
    },
    activeCategory (name) {
      let result = 0
      const cnt = this.navCategory.items.length
      for (let i = 0; i < cnt; i++) {
        const item = this.navCategory.items[i]
        if (item.name === name) {
          result = i
          break
        }
      }

      this.navCategory.active = result
    },
    focusCategory (item) {
      this.navCrumbs = []
      this.navCrumbs.push(
        { ...this.navHome },
        { ...this.navSelf },
        {
          text: item.title,
          disabled: true,
          href: '',
        }
      )

      this.currentCategory = this.getCategory(item.name)
      Object.assign(this.navPage, this.currentCategory.pagination)
      this.navigated = item.navigated
    },
    onCategory (ev) {
      const item = ev.item
      this.focusCategory(item)
      this.activeCategory(item.name)
    }
  }
}
