
import api from '@/api/co.api'
import app from '@/api/co.app'
import act from '@/api/co.action'
import notice from '@/api/co.notice'

import mixNoticesCategory from './mix.notice.category'

export default {
  mixins: [mixNoticesCategory],
  data () {
    return {
      limitCount: 3
    }
  },
  methods: {
    onNotice (ev, category) {
      act.navigate({
        that: this,
        pagename: api.page.noticeDetail.name,
        item: ev.item,
        category
      })
    },
    toNotices (ev, category) {
      act.navigate({
        that: this,
        pagename: api.page.noticeList.name,
        item: {
          type: 'notices'
        },
        category
      })
    },
    changePagination (number, name) {
      const cat = this.getCategory(name)
      const params = cat.params
      const data = cat.params.data
      const limit = parseInt(data.limit, 10) || 10
      const index = parseInt(number, 10) || 1

      cat.pagination.current = index
      data.offset = (index - 1) * limit
      params.reload = true
      params.initial = false
      api.http.getItems(params)
      // console.log('number: %o, name: %o', number, name)
    },
    initCategoryParams (category = null) {
      const me = this
      if (!category) {
        return
      }
      const executing = function () {
      }

      const executed = function (res) {
        // console.log('res: %o', res)
        const data = category.params.data
        const pagination = category.pagination
        const limit = parseInt(data.limit) || 10
        pagination.length = Math.ceil(res.total / limit)
        if (category.params.initial === true) {
          category.latest = []
          const len = category.items.length
          const cnt = len > me.limitCount ? me.limitCount : len
          for (let i = 0; i < cnt; i++) {
            const item = category.items[i]
            category.latest.push(item)
          }
        }
        // console.log('pagination: %o', pagination)
      }
      category.params = notice.getParams({
        siteId: app.init.siteId,
        type: category.type,
        topicId: category.topicId || '',
        limit: '10',
        caches: category.items,
        executing,
        executed
      })
    },
    initParams () {
      // const me = this
      const keys = this.catListKeys
      const cats = this.navCategory.items
      for (const i in cats) {
        const cat = cats[i]
        if (keys.indexOf(cat.name) !== -1) {
          this.initCategoryParams(cat)
        }
      }
    },
    loadCategoryNotices (category = null, reload = true) {
      if (!category) {
        return
      }
      const params = category.params
      if (params) {
        params.reload = reload
        params.initial = true
        api.http.getItems(params)
      }
    },
    loadData (name = '', reload = true) {
      if (name) {
        const cat = this.getCategory(name)
        this.loadCategoryNotices(cat, reload)
      } else {
        const cats = this.navCategory.items
        for (const i in cats) {
          const cat = cats[i]
          this.loadCategoryNotices(cat, reload)
        }
      }
    }
  }
}
