
import api from '@/api/co.api'

const PartNames = {
  NAVIGATE: 'navigate',
  INFORMS: 'informs',
  NEWS: 'news',
  PUBLICS: 'publics',
  PARKS: 'parks',
  PROJECTS: 'projects',
  YUANSHI: 'yuanshi'
}

const CatNavigate = {
  title: '导航台',
  name: 'navigate',
  navigated: true
}

const CatInform = {
  title: '通知公告',
  name: 'informs',
  topicId: '',
  type: api.comm.NoticeTypes.INFO,
  navigated: false,
  params: null,
  items: [],
  latest: [],
  pagination: {
    current: 1,
    length: 15,
    totalVisible: 7
  },
  buttons: [{
    icon: 'mdi-chevron-right'
  }]
}

const CatNews = {
  title: '最新资讯',
  name: 'news',
  href: '/notice/list',
  topicId: '',
  type: api.comm.NoticeTypes.NEWS,
  navigated: false,
  params: null,
  items: [],
  latest: [],
  pagination: {
    current: 1,
    length: 15,
    totalVisible: 7
  },
  buttons: [{
    icon: 'mdi-chevron-right'
  }]
}

const CatPublic = {
  title: '公开信息',
  name: 'publics',
  href: '/notice/list',
  topicId: '',
  type: api.comm.NoticeTypes.PUBLICS,
  navigated: false,
  params: null,
  items: [],
  latest: [],
  pagination: {
    current: 1,
    length: 15,
    totalVisible: 7
  },
  buttons: [{
    icon: 'mdi-chevron-right'
  }]
}

const CatPark = {
  title: '园区文件',
  name: 'parks',
  href: '/notice/list',
  topicId: '',
  type: api.comm.NoticeTypes.PARKS,
  navigated: false,
  params: null,
  items: [],
  latest: [],
  pagination: {
    current: 1,
    length: 15,
    totalVisible: 7
  },
  buttons: [{
    icon: 'mdi-chevron-right'
  }]
}

const CatProject = {
  title: '重大项目',
  name: 'projects',
  href: '/notice/list',
  topicId: '',
  type: api.comm.NoticeTypes.PROJECTS,
  navigated: false,
  params: null,
  items: [],
  latest: [],
  pagination: {
    current: 1,
    length: 15,
    totalVisible: 7
  },
  buttons: [{
    icon: 'mdi-chevron-right'
  }]
}

const CatYuanShi = {
  title: '月谈园事',
  name: 'yuanshi',
  href: '/notice/list',
  topicId: '38836731882504192',
  type: api.comm.NoticeTypes.NEWS,
  navigated: false,
  params: null,
  items: [],
  latest: [],
  pagination: {
    current: 1,
    length: 15,
    totalVisible: 7
  },
  buttons: [{
    icon: 'mdi-chevron-right'
  }]
}

const noticePreset = {
  Names: PartNames,
  Parts: {
    [PartNames.NAVIGATE]: CatNavigate,
    [PartNames.INFORMS]: CatInform,
    [PartNames.NEWS]: CatNews,
    [PartNames.PUBLICS]: CatPublic,
    [PartNames.PARKS]: CatPark,
    [PartNames.PROJECTS]: CatProject,
    [PartNames.YUANSHI]: CatYuanShi
  }
}

export default noticePreset
