<template>
  <section>
    <land-hero-alts
      v-if="banItems.length"
      :items="banItems"
      :assist-color="banApp.assistColor"
      :divisible="banApp.divisible"
      :breadcrumbs="banApp.crumbs"
    />
    <land-section
      id="noticecenter"
      space="40"
      color="grey lighten-4"
    >
      <v-container
        style="max-width: 1200px;"
      >
        <v-row
          no-gutters
          justify="center"
        >
          <v-col
            cols="12"
            md="3"
            class="hidden-sm-and-down"
          >
            <land-categories
              :title="navCategory.title"
              :items="navCategory.items"
              @click="onCategory"
            />
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <div
              class="d-flex flex-row justify-space-between align-center pt-1 pb-5"
            >
              <v-breadcrumbs
                :items="navCrumbs"
                large
                class="pa-0"
              />
              <v-app-bar-nav-icon
                class="hidden-md-and-up"
                @click="drawer = !drawer"
              />
            </div>

            <v-row
              class="pa-5 white"
            >
              <!-- 通知公告 -->
              <v-col
                v-if="navigated"
                cols="12"
              >
                <land-navigation
                  :title="catInform.title"
                  :items="catInform.buttons"
                  @click="toNotices($event, catInform)"
                />
              </v-col>
              <v-col
                v-if="navigated"
                cols="12"
              >
                <land-plain-notices
                  :items="catInform.latest"
                  back-color="transpart"
                  card-color="grey lighten-4"
                  @click="onNotice($event, catInform)"
                />
              </v-col>

              <!-- 最新资讯 -->
              <v-col
                v-if="navigated"
                cols="12"
              >
                <land-navigation
                  :title="catNews.title"
                  :items="catNews.buttons"
                  margin="mt-4"
                  @click="toNotices($event, catNews)"
                />
              </v-col>
              <v-col
                v-if="navigated"
                cols="12"
                class="pa-0"
              >
                <land-gallery
                  :items="catNews.latest"
                  back-color="grey lighten-5"
                  @click="onNotice($event, catNews)"
                />
              </v-col>

              <!-- 互动空间 -->
              <v-col
                v-if="navigated"
                cols="12"
              >
                <land-navigation
                  :title="catPublic.title"
                  :items="catPublic.buttons"
                  margin="mt-4"
                  @click="toNotices($event, catPublic)"
                />
              </v-col>
              <v-col
                v-if="navigated"
                cols="12"
                class="pa-0"
              >
                <land-gallery
                  :items="catPublic.latest"
                  back-color="grey lighten-5"
                  @click="onNotice($event, catPublic)"
                />
              </v-col>

              <!-- 园区服务 -->
              <v-col
                v-if="navigated"
                cols="12"
              >
                <land-navigation
                  :title="catPark.title"
                  :items="catPark.buttons"
                  margin="mt-4"
                  @click="toNotices($event, catPark)"
                />
              </v-col>
              <v-col
                v-if="navigated"
                cols="12"
                class="pa-0"
              >
                <land-gallery
                  :items="catPark.latest"
                  back-color="grey lighten-5"
                  @click="onNotice($event, catPark)"
                />
              </v-col>

              <!-- 园区发现 -->
              <v-col
                v-if="navigated"
                cols="12"
              >
                <land-navigation
                  :title="catProject.title"
                  :items="catProject.buttons"
                  margin="mt-4"
                  @click="toNotices($event, catProject)"
                />
              </v-col>
              <v-col
                v-if="navigated"
                cols="12"
                class="pa-0"
              >
                <land-gallery
                  :items="catProject.latest"
                  back-color="grey lighten-5"
                  @click="onNotice($event, catProject)"
                />
              </v-col>

              <v-col
                v-if="!navigated"
                cols="12"
              >
                <land-plain-notices
                  :items="currentCategory.items"
                  back-color="white"
                  card-color="grey lighten-4"
                  padding="py-4"
                  @click="onNotice($event, currentCategory)"
                />

                <div
                  v-if="navPage.length"
                  class="d-flex flex-row mt-4"
                >
                  <v-pagination
                    v-model="navPage.current"
                    :length="navPage.length"
                    :total-visible="navPage.totalVisible"
                    @input="changePagination($event, currentCategory.name)"
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-navigation-drawer
        v-model="drawer"
        fixed
        overlay-color="secondary"
        overlay-opacity=".8"
        temporary
      >
        <v-list>
          <v-subheader>{{ navCategory.title }}</v-subheader>
          <v-list-item-group
            v-model="navCategory.active"
          >
            <v-list-item
              v-for="(item, index) in navCategory.items"
              :key="index"
            >
              <v-list-item-content>
                <v-list-item-title
                  class="text-subtitle-1"
                >
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </land-section>
    <keep-in-touch />
  </section>
</template>

<script>
  import mixNoticeBanner from '@/pages/mixins/notice/mix.notice.banner'
  import mixNoticeMain from '@/pages/mixins/notice/mix.notice.main'
  export default {
    components: {
      KeepInTouch: () => import('@/pages/sections/KeepInTouch.vue')
    },
    metaInfo: { title: '资讯中心' },
    mixins: [
      mixNoticeBanner,
      mixNoticeMain
    ],
    data () {
      return {
        drawer: false,
        backColor: 'grey lighten-4',
      }
    },
    created () {
      this.setCrumbs(null)
      this.initBannerParams({
        topicId: this.banTopicId,
        categoryId: this.banCategoryId
      })
      this.loadBanners()

      this.initParams()
      this.loadData()
    },
    methods: {}
  }
</script>
